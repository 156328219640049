import common from './common';

/**
 * @description 将common message 注册到page message中(目前支出cn en)
 */
export function initCommonToMessage(message) {
    if (message && common) {
        message.en.common = common.en;
        message.cn.common = common.cn;
        return message;
    }
    throw Error('message or common is undefined')
}
