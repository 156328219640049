<template>
    <div class="weChat">
        <img src="@/assets/images/Wechat.jpg" alt="WeChat" />
        <p>Scan the QR code with WeChat to get more service info</p>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
