export const message = {
    en: {
        privatMessages: 'Private messages',
        // 平台通知-（无接口、无数据，先删掉了）
        platformNotification: 'Platform notification',
        noMessage: 'No info currently',
        byName: 'Mi Enterprise Services',
        hello: 'Hello，',
        back: 'Returned list',
    },
    cn: {
        privatMessages: '站内信',
        // 平台通知-（无接口、无数据，先删掉了）
        platformNotification: '平台通知',
        noMessage: '暂无信息',
        byName: '小米政企服务',
        hello: '尊敬的用户，',
        back: '返回列表',
    },
};

export default message;
