import axios from 'axios';
import {Key_i18n, Value_Default_i18n} from '@/model';
import {getT} from '../model/i18n/apiMessage';

export const HostConfig = {
    en: {
        test: 'http://staging-srv-intl.b.mi.com/api/',
        prod: 'https://srv.intl.b.mi.com/api/',

        href: {
            test: 'http://staging-global-dev.b.mi.com',
            prod: 'https://global.dev.b.mi.com',
        },
    },
    cn: {
        test: 'http://staging-srv-intl.b.mi.com/',
        prod: 'https://srv.intl.b.mi.com/',

        href: {
            test: 'http://staging-global-dev.b.mi.com',
            prod: 'https://global.dev.b.mi.com',
        },
    },
};

// 消息tongzh8i
export const devOperates = 'developer/getDevOperates';

// 阅读消息
export const readNews = 'developer/updateIsNew';

// 开发者状态 sign mid
export const userStatus = 'developer/judgeRegisterOrNot';

// 开发者基本信息 sign mid
export const userInfo = 'developer/getDeveloper';
// 更新开发者信息
/**
developerName: gg打野2
companyName: 山上1
businessLicense: 12312123
licensePicUrl: (binary)
address: 123
contactEmail: 32@qq.com
contactQq: 12233442132
miId: 3150276528
 */
export const updateInfo = 'developer/update';
// 注册开放者信息
export const registerInfo = 'developer/register';


// 测试证书 ?start=0&genrStatus=&sortType=1&limit=20&sign=76A410C8A8EBE37580409D1AD5F5A52C&userId
export const testList = 'bizMode/listCertificateApplies';
// 申请测试证书
/**
agencyName: 上传试试
imies: 1222,12222
miuiVersion: 1
sign: 5795EFB68CFB7B3035650A5DC56B5979
 */
export const postApplyTest = 'bizMode/applyCertificate';

// 隐私政策和应用列表是同一个接口 参数?start=0&applyStatus=&sortType=1&limit=20&sign
export const appList = 'bizMode/listCodeApplies';

// 开发者激活码信息
// rom数量 无参数
export const romCount = 'bizMode/surplusUnlockCount';
// 激活码列表 start limit sign
export const codeList = 'bizMode/listCodes';

// 设置邮箱地址
export const setEmail = 'bizMode/sendCodeMailToDev';
//

// 上传隐私 post file
export const postUploadPrivcy = 'bizMode/uploadPrivateFile';
// 上传apk
export const postUploadAPK = 'bizMode/uploadAndCheckApk';
export const postApplyCodee = 'bizMode/applyCode';

// 销售信息 start limit sign
export const cusList = 'developer/client/show';
// 信息状态标记
export const editCusStatus = 'developer/client/editResult';

// 旧平台老用户迁移
export const moveData = 'developer/moveData';

// 下载接口
export const bizDownload = 'bizMode/download';

axios.axoisVm = null;
// vm弹窗
export function initVm(vm) {
    if (vm) {
        axios.axoisVm = vm;
    }
}

function handleError(error, url = '') {
    if (error instanceof Error) {
        // todo 上报网络异常
        console.log('网络异常', url);
        console.error(error);
        if (axios.axoisVm) {
            axios.axoisVm.$message({type: 'error', message: error.message});
        }
    } else {
        console.log(JSON.stringify(error), url);
    }
}

function handleRes(res, url) {
    const {code, desc} = res.data;
    console.log('handleRes', url, getT(code), code);
    if (code !== 200 && axios.axoisVm) {
        axios.axoisVm.$message({type: 'error', message: getT(code) || desc});
    }
}

export function get(url, config = {}) {
    const path = `${getHost()}${url}`;
    config.withCredentials = true;
    return axios
        .get(path, config)
        .then(res => {
            if (!config.notDefaultHandle) {
                // 异常状态统一处理
                handleRes(res, path);
            }
            return res.data;
        })
        .catch(error => {
            handleError(error, path);
            // 通用error处理
            console.error('通用 error', error);
            throw error;
        });
}

export function post(url, data, config = {}) {
    const path = `${getHost()}${url}`;
    config.withCredentials = true;
    return axios
        .post(path, data, config)
        .then(res => {
            if (!config.notDefaultHandle) {
                // 异常状态统一处理
                handleRes(res, path);
            }

            return res.data;
        })
        .catch(error => {
            // 通用error处理
            handleError(error);
            console.error('通用 error', error);
            throw error;
        });
}

export function getLocale() {
    return localStorage.getItem(Key_i18n) || Value_Default_i18n;
}

export function getHost() {
    const locale = getLocale();
    const config = HostConfig[locale] || HostConfig[Value_Default_i18n];
    return config[process.env.VUE_APP_ENV] || '/api/';
}

export function getHref() {
    const locale = getLocale();
    const config = HostConfig[locale] || HostConfig[Value_Default_i18n];
    return config.href[process.env.VUE_APP_ENV] || location.href;
}

export function getLoginoutAccount() {
    if (process.env.VUE_APP_ENV === 'prod') {
        return 'https://account.xiaomi.com';
    }

    return 'http://account.preview.n.xiaomi.net';
}
