/**
 * @file 插件，增加语言改变时触发的声明周期（基于i18n的使用）
 */

const ChangeMap = {
    isBind: false,
    componentList: [],
};
function initEvent(vm) {
    // 组件实例化时收集需要的组件
    if (vm === vm.$root) {
        return false;
    }
    ChangeMap.componentList.push(vm);
    if (ChangeMap.isBind) {
        return false;
    }

    ChangeMap.isBind = true;
    // console.log('。。。。----', vm === vm.$root);
    vm.$root.$on('i18n-locale-change', (params) => {
        // console.log('。。。。', params);

        const {componentList} = ChangeMap;
        const {length} = componentList;
        for (let i = length - 1; i >= 0; i--) {
            const item = componentList[i];
            if (typeof item.updateI18n === 'function') {
                item.updateI18n(params);
            }
            else {
                // 删除无用的组件
                componentList.splice(i, 1);
            }
        }

        
    });
}

export default {
    install(Vue) {
        Vue.mixin({
            beforeCreate() {
                initEvent(this);
            },
            created() {
                if (typeof this.updateI18n === 'function') {
                    return this.updateI18n();
                }
                return;
            },

            destroyed() {
                const {componentList} = ChangeMap;
                const {length} = componentList;
                for (let i = length - 1; i >= 0; i--) {
                    const item = componentList[i];
                    if (item === this) {
                        // 卸载无用的组件
                        componentList.splice(i, 1);
                        break;
                    }
                }
                return;
            },
        });
    },
};
