import Vue from 'vue';
import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";
import {sentryConfig} from  './config';

if (process.env.VUE_APP_ENV === 'prod') {
    Sentry.init({
        Vue,
        dsn: sentryConfig.url,
        integrations: [
          new Integrations.BrowserTracing(),
        ],
      
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });

    Vue.prototype.$Sentry = Sentry;

    console.log('sentry');
}
