class ShowBox {
    constructor() {
        if (ShowBox.that) {
            return ShowBox.that;
        }

        this.engineVm = null;
        this.showList = [];

        ShowBox.that = this;
        return this;
    }

    // 根组件实例化调用
    initVm(vm) {
        this.engineVm = vm;
    }

    // 其他组件
    showFn(message, title, {confirmButtonText, cancelButtonText, messageSlotFn}) {
        if (this.engineVm) {
            this.engineVm.boxShow(message, title, {
                confirmButtonText,
                cancelButtonText,
                messageSlotFn,
            });

            return new Promise(resolve => {
                this.engineVm.$watch('isConfirmShow', value => {
                    if (!value) {
                        resolve(this.engineVm.confirmChangeType);
                    }
                });
            });
        } else {
            throw Error('showBox.engineVm is undefined');
        }
    }
}

const showBox = new ShowBox();

export default {
    install(Vm) {
        // 根组件初始化调用
        Vm.prototype.$initShowBox = function(vm) {
            showBox.initVm(vm);
        };

        // 其他组件调用，返回promise
        Vm.prototype.$myConfirm = function(message, title, {confirmButtonText, cancelButtonText, messageSlotFn} = {}) {

            title = title || this.$t('common.notice');
            confirmButtonText = confirmButtonText || this.$t('common.btnEnter');
            cancelButtonText = cancelButtonText = this.$t('common.btnClose')
            return showBox.showFn(message, title, {
                confirmButtonText,
                cancelButtonText,
                messageSlotFn,
            });
        };
    },
};

// function bodyHide() {
//     if (window.document) {
//         const bodyClassName = document.body.className;
//         document.body.className = bodyClassName + ' hide-over';
//     }
// }
// function bodyShow() {
//     if (window.document) {
//         let bodyClassName = document.body.className;
//         bodyClassName = bodyClassName.replace('hide-over', '');
//         bodyClassName = bodyClassName.replace('  ', ' ');
//         document.body.className = bodyClassName;
//     }
// }
