<template>
    <div class="my-mask" @click.stop="btnHand('close')">
        <div @click.stop class="my-confirm" :class="isShow ? 'active' : ''">
            <h3 class="confirm-title">{{title}}</h3>
            <div class="confirm-content">
                <template v-if="isSlotDefault">
                    <slot></slot>
                </template>
                <template v-else>
                    {{message}}
                </template>
            </div>
            <div class="confirm-footer">
                <div @click.stop="btnHand('close')" v-if="closeButtonText" class="confirm-btn-close confirm-btn">{{closeButtonText}}</div>
                <div @click.stop="btnHand(null, 'enter')" class="confirm-btn-enter confirm-btn">{{confirmButtonText}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            desc: 'message内容',
            type: String,
        },
        title: {
            desc: '提示框的title，默认“提示”',
            type: String,
            default() {
                return '提示';
            },
        },
        confirmButtonText: {
            desc: '确认按钮，默认值“确认”',
            type: String,
            default() {
                return '确认';
            },
        },
        closeButtonText: {
            desc: '取消按钮，默认值 空',
            type: String,
        },

    },
    data() {
        return {
            isSlotDefault: false,
            isShow: false,
        };
    },
    created() {
        this.isSlotDefault = !!this.$slots.default;
        console.log(this, !!this.$slots.default, this.isSlotDefault);
    },

    mounted() {
        setTimeout(() => {
            this.isShow = true;
        });
    },

    methods: {
        btnHand(errorType, type) {
            console.log('confirmCallback');
            this.$emit('confirmCallback', errorType, type);
        },
    },
}
</script>

<style lang="less">
.my-mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
    z-index: 999999999;
}

.my-confirm {
    /* position: absolute; */
    margin: 300px auto;
    width: 420px;
    padding-bottom: 10px;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    font-size: 18px;
    box-shadow: 0 2px 12px 0 rgb(0, 0, 0, .1);
    text-align: left;
    overflow: hidden;
    transition: transform .5s;
    transform: translateY(-200px);

    &.active {
        transform: translateY(0);
    }

    .confirm-title {
        padding: 20px 20px 10px 20px;

    }

    .confirm-content {
        padding: 30px 20px;
        min-height: 70px;
        
    }

    .confirm-footer {
        float: right;
        overflow: hidden;
        padding: 0 10px;

        .confirm-btn {
            margin-left: 10px;
            text-align: center;
            border-radius: 4px;
            font-size: 14px;
            padding: 12px 20px;
            border: 1px solid #dcdfe6;
            cursor: pointer;
            color: #606266;
            text-align: center;

            &.confirm-btn-enter {
                color: #fff;
                background: #409eff;
                border-color: #409eff;
                

            }
        }
    }

}
</style>