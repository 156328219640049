export const oneTrackConfig = {
    appId: '31000000592',
    region: 'CN',
};

export const sentryConfig = {
    appId: 'e8cbed234db24a4182bab1759b8a2708',
    url: 'https://e8cbed234db24a4182bab1759b8a2708@sentry.d.xiaomi.net/710',
};

export const apmConfig = {
    group: 'mi-tob-dev',
    serverUrl: '//apm-rum.inf.miui.com',
    serviceName: 'g-dev',
    serviceVersion: process.env.CI_PIPELINE_ID,
    environment: process.env.APM_ENVIRONMENT,

};