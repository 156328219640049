import Vue from 'vue';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import { apmConfig } from './config';


const {serverUrl, serviceName, serviceVersion, environment, group} = apmConfig;
if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_ENV === 'prod') {

    Vue.use(ApmVuePlugin, {
      config: {
        serverUrl,
        serviceName,
        serviceVersion,
        environment
      }
    })
}


// vue 实例化 后
export function initAPMLabel(vm) {
    if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_ENV === 'prod') {
        if (vm && vm.$apm) {
            vm.$apm.addLabels({
                group,
            });
        }
        else {
            console.error('vm.$apm is undefined');
        }
    }
    else {
        console.log('非正式环境，不初始化apm');
    }
    
    
}
