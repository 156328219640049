import {initCookieConfig, clearCookie} from './cookie-config';
import {
    initVm,
    getLocale,
    get,
    getHost,
    getHref,
    getLoginoutAccount,
    devOperates,
    readNews,
    userStatus,
    userInfo,
    updateInfo,
    registerInfo,
    post,
    testList,
    postApplyTest,
    appList,
    postUploadPrivcy,
    postUploadAPK,
    postApplyCodee,
    romCount,
    codeList,
    cusList,
    editCusStatus,
    moveData,
    setEmail,



} from './api';
import {
    getCookieValueByName,
    generateSign,
} from '@/utils/tool';




function getMitobBusiness() {
    let sid = 'mitob_business'
    
    if (getLocale() === 'en' || process.env.NODE_ENV === 'development') {
        sid = 'mitob_business_sgp';
    }
    const key = `${sid}_ph`;
    const value = getCookieValueByName(key)

    return {
        sid,
        key,
        value,
    }
}


// export function postUpdate() {
//     return 
// }


// 登录
export function login() {
    if (process.env.NODE_ENV !== 'development') {
        location.href = `${getHost()}login/passport?callBack=${getHref()}`;
        return Promise.resolve({});
    }
    return initCookieConfig();

}


// 注册
export function register() {
    const {sid} = getMitobBusiness();
    const host = getHost();
    const sign = process.env.VUE_APP_ENV === 'prod'? 'OmxPOUH8YlEwncXNN0WAsyiC92c=' : '+Aothz8rYrCWss8etMmp+hof0b4=';
    const registerUrl = `${
        getLoginoutAccount()
        // 'http://account.preview.n.xiaomi.net/pass/serviceLogin?'
    }/pass/register?callback=${
        encodeURIComponent(`${host}sts?sign=${
            encodeURIComponent(sign)}&followup=${
            // encodeURIComponent(`${'http://staging-srv-intl.b.mi.com/'}sts?sign=${encodeURIComponent('BCewjn7ww7SOZNU1M3yGHGoGD8I=')}&followup=${
            encodeURIComponent(`${host}login/passport?callBack=${
                // encodeURIComponent(`${'http://staging-srv-intl.b.mi.com/'}developer/passport?callBack=${
                getHref()
                // encodeURIComponent('https://staging-global-dev.b.mi.com')
            }`)
        }`)
    }&sid=${
        sid
    }&_locale=eh_US`;
    // 0 http://account.preview.n.xiaomi.net/fe/service/register?_locale=eh_US&source=&region=US&sid=mitob_business_sgp&qs=callback%3Dhttp%253A%252F%252Fstaging-srv-intl.b.mi.com%252Fsts%253Fsign%253DpOil0rKxh4lTcNz446QCM8ymFEw%25253D%2526followup%253Dhttp%25253A%25252F%25252Fstaging-srv-intl.b.mi.com%25252Flogin%25252Fpassport%25253FcallBack%25253Dhttp%25253A%25252F%25252Fstaging-global-dev.b.mi.com%26sid%3Dmitob_business_sgp%26_locale%3Deh_US&callback=http%3A%2F%2Fstaging-srv-intl.b.mi.com%2Fsts%3Fsign%3DpOil0rKxh4lTcNz446QCM8ymFEw%253D%26followup%3Dhttp%253A%252F%252Fstaging-srv-intl.b.mi.com%252Flogin%252Fpassport%253FcallBack%253Dhttp%253A%252F%252Fstaging-global-dev.b.mi.com&_uRegion=US
    // 1 http://account.preview.n.xiaomi.net/fe/service/register?_locale=eh_US&source=&region=US&sid=mitob_business_sgp&qs=callback%3Dhttp%253A%252F%252Fstaging-srv-intl.b.mi.com%252Fsts%253Fsign%253DpOil0rKxh4lTcNz446QCM8ymFEw%25253D%2526followup%253Dhttp%25253A%25252F%25252Fstaging-srv-intl.b.mi.com%25252Flogin%25252Fpassport%25253FcallBack%25253Dhttp%25253A%26sid%3Dmitob_business_sgp%26_locale%3Deh_US&callback=http%3A%2F%2Fstaging-srv-intl.b.mi.com%2Fsts%3Fsign%3DpOil0rKxh4lTcNz446QCM8ymFEw%253D%26followup%3Dhttp%253A%252F%252Fstaging-srv-intl.b.mi.com%252Flogin%252Fpassport%253FcallBack%253Dhttp%253A&_uRegion=US
    // "http://account.preview.n.xiaomi.net/pass/register?callback=http%3A%2F%2Fstaging-srv-intl.b.mi.com%2Fsts%3Fsign%3DpOil0rKxh4lTcNz446QCM8ymFEw%253D%26followup%3Dhttp%253A%252F%252Fstaging-srv-intl.b.mi.com%252Flogin%252Fpassport%253FcallBack%253Dhttp%253A%252F%252Fstaging-global-dev.b.mi.com&sid=mitob_business_sgp&_locale=eh_US
    // "http://account.preview.n.xiaomi.net/pass/register?callback=http%3A%2F%2Fstaging-srv-intl.b.mi.com%2Fapi%2Fsts%3Fsign%3DpOil0rKxh4lTcNz446QCM8ymFEw%253D%26followup%3Dhttp%253A%252F%252Fstaging-srv-intl.b.mi.com%252Fapi%252Flogin%252Fpassport%253FcallBack%253Dhttp%253A%252F%252Fstaging-global-dev.b.mi.com&sid=mitob_business_sgp&_locale=eh_US
    //http://account.preview.n.xiaomi.net/pass/register?callback=%2Fapi%2Fsts%3Fsign%3DpOil0rKxh4lTcNz446QCM8ymFEw%253D%26followup%3D%252Fapi%252Flogin%252Fpassport%253FcallBack%253Dhttp%25253A&sid=mitob_business_sgp&_locale=eh_US
    // http://account.preview.n.xiaomi.net/pass/register?callback=%2Fapi%2Fsts%3Fsign%3DBCewjn7ww7SOZNU1M3yGHGoGD8I%253D%26followup%3D%252Fapi%252Fdeveloper%252Fpassport%253FcallBack%253Dhttp%25253A%25252F%25252Flocalhost%25253A8080%25252F&sid=mitob_business_sgp
    // http://account.preview.n.xiaomi.net/pass/register?callback=https%3A%2F%2Fstaging-srv-new.b.mi.com%2Fsts%3Fsign%3DqiPao%252Fnj8lvjfCh4wGYWX4XS4NM%253D%26followup%3Dhttps%253A%252F%252Fstaging-srv-new.b.mi.com%252Flogin%252Fpassport%253FcallBack%253Dhttps%25253A%25252F%25252Fnew-staging-dev.b.mi.com&sid=mitob_business
    // https://staging-srv-new.b.mi.com/sts?sign=qiPao%2Fnj8lvjfCh4wGYWX4XS4NM%3D&followup=https%3A%2F%2Fstaging-srv-new.b.mi.com%2Flogin%2Fpassport%3FcallBack%3Dhttps%253A%252F%252Fnew-staging-dev.b.mi.com
    if (process.env.NODE_ENV !== 'development') {
        // if (process.env.VV === 'prod') {
        //     location.href = '${getLoginoutAccount()}/pass/register?callback=https%3A%2F%2Fsrv.intl.b.mi.com%2Fsts%3Fsign%3DBCewjn7ww7SOZNU1M3yGHGoGD8I%253D%26followup%3Dhttps%253A%252F%252Fsrv.intl.b.mi.com%252Fdeveloper%252Fpassport%253FcallBack%253Dhttps%25253A%25252F%25252Fglobal.dev.b.mi.com&sid=mitob_business_sgp';
        // }
        // else {
            
        // }
        location.href = registerUrl;

        return Promise.resolve({});
    }
    console.log('registerUrl', registerUrl);
    // return login();
    
}

// 登出
export function loginout() {
    if (process.env.NODE_ENV !== 'development') {
        const userId = getCookieValueByName('userId');
        return location.href = `${getLoginoutAccount()}/pass/logout?callback=${getHref()}&sid=mitob_business_sgp&userId=${userId}`;
    }
    return clearCookie();
    
}

// 通知，代办
export function getDevOperates() {
    const userId = getCookieValueByName('userId');

    let sign = generateSign({
        miId: userId,
    });

    return get(devOperates, {
        params: {
            miId: userId,
            sign,
        },
    });
}
// 阅读通知
export function getReadNews(operateId) {

    const sign = generateSign({operateId});
    return get(readNews, {
        params: {
            operateId,
            sign,
        }
    })
}

// 用户状态，展示不同阶段的页面(用户状态从cookie中取)
export function getUserStatus(u, {notDefaultHandle}) {
    const userId = getCookieValueByName('userId');
    let sign = generateSign({
        miId: userId,
    });

    return get(userStatus, {
        params: {
            miId: userId,
            sign,
        },
        notDefaultHandle,
    });
}

// 开发者状态，展示不同阶段的页面(用户状态从cookie中取)
export function getUserInfo() {
    const userId = getCookieValueByName('userId');
    let sign = generateSign({
        miId: userId,
    });

    return get(userInfo, {
        params: {
            miId: userId,
            sign,
        },
    });
}

// 开发者信息更新
export function updateUserInfo({
    developerName = 'gg打野2',
    companyName = '山上1',
    businessLicense = '12312123',
    licensePicUrl = null,
    address = '123',
    contactEmail = '32@qq.com',
    contactQq = '12233442132',
    isNew = 0,
} = {}) {
    const userId = getCookieValueByName('userId');
    const formData = new FormData();
    formData.append('developerName', developerName);
    formData.append('companyName', companyName);
    formData.append('businessLicense', businessLicense);
    formData.append('file', licensePicUrl || new File([], '', {type: 'application/octet-stream'}));
    formData.append('address', address);
    formData.append('contactEmail', contactEmail);
    formData.append('contactQq', contactQq);
    formData.append('miId', userId);

    
    const {key: mitobBusKey, value: mitobBusinessPh} = getMitobBusiness();
    
    const formObj = {developerName, companyName, businessLicense, address, contactEmail, contactQq, miId: userId, mitob_business_ph: mitobBusinessPh};

    return post(`${isNew ? registerInfo : updateInfo}?sign=${generateSign(formObj)}&${mitobBusKey}=${mitobBusinessPh}`, formData, {
        withCredentials: true,
        crossDomain: true,
        headers: {
            
        }
    });
}

// 和内置的uuid不一样
const ConfigUuid = '5cdd8678-cddf-4269-ab73-48387445bba8';
export function getTestList({page = 1, status = '', sortType = 1, max = 20} = {}) {
    const params = {
        current: page,
        size: max,
        genrStatus: status,
        sortType: sortType,
    }
    console.log(params)
    const sign = generateSign(params, ConfigUuid);
    params.sign = sign;
    return get(testList, {
        params,
    })
}
// 申请测试证书
export function postApplyTestCerty({agencyName, imies, miuiVersion = 1} = {}, {isHandle = 0} = {}) {
    const {key, value} = getMitobBusiness();
    const params = {
        agencyName,
        imies,
        miuiVersion,
        mitob_business_sgp_ph: decodeURIComponent(value),
    }
    const sign = generateSign(params, ConfigUuid);
    const formData = new FormData();
    formData.append('agencyName', agencyName);
    formData.append('imies', imies);
    formData.append('miuiVersion', miuiVersion);
    formData.append('sign', sign);

    return post(`${postApplyTest}?${key}=${value}`, formData, {isHandle});
}
// 应用列表
export function getAppList({page = 1, status = '', sortType = 1, max = 20} = {}) {
    const params = {
        current: page,
        size: max,
        applyStatus: status,
        sortType: sortType,
    }
    console.log(params)
    const sign = generateSign(params, ConfigUuid);
    params.sign = sign;
    return get(appList, {
        params,
    })
}

// 申请应用
export function postApplyAppCode({agencyName, packageUrl, privacyPolicy, miuiVersion = 1} = {}) {
    const {key, value} = getMitobBusiness();
    const params = {
        agencyName,
        packageUrl,
        privacyPolicy,
        miuiVersion,
        mitob_business_sgp_ph: decodeURIComponent(value),
    }
    const sign = generateSign(params, ConfigUuid);
    const formData = new FormData();
    formData.append("agencyName", agencyName);
    formData.append("packageUrl", packageUrl);
    formData.append("privacyPolicy", privacyPolicy);
    formData.append("miuiVersion", miuiVersion);
    formData.append("sign", sign);

    return post(`${postApplyCodee}?${key}=${value}`, formData);
}

export function uploadFileByApp(url, file) {
    // 单纯的文件上传
    const {key, value} = getMitobBusiness();

    const formData = new FormData();
    formData.append('file', file)
    return post(`${url}?${key}=${value}`, formData);
}
// 上传apk
export function uploadAPK(file) {
    return uploadFileByApp(postUploadAPK, file);
}
// 上传隐私政策
export function uploadPervcy(file) {
    return uploadFileByApp(postUploadPrivcy, file);
}

// 激活码次数
export function getCodeCount() {
    return get(romCount);
}

export function getCodeList({page = 1, max = 20} = {}) {
    const params = {
        current: page,
        size: max,
    };
    const sign = generateSign(params, ConfigUuid);

    params.sign = sign;

    return get(codeList, {
        params,
    })
}

export function getCusList({page = 1, status = '', result = 3, order = 'desc', max = 20} = {}) {
    const params = {
        current: page,
        size: max,
        status,
        result,
        order,
    };

    const sign = generateSign(params, ConfigUuid);
    params.sign = sign;


    return get(cusList, {
        params,
    })
}

export function postCusStatus({id, result, reason = ''} = {}) {
    const {key, value} = getMitobBusiness();
    const formData = new FormData();
    formData.append('id', id);
    formData.append('result', result);
    formData.append('reason', reason);
    return post(`${editCusStatus}?${key}=${value}`, formData);
}

// 迁移旧平台的用户
export function getMoveData() {
    const miId = getCookieValueByName('userId');
    const sign = generateSign({miId});
    return get(moveData, {
        params: {
            sign,
            miId,
        }
    })
}

// 设置邮箱
export function getSetEmail({applyId, contactEmail}) {
    const sign = generateSign({applyId, contactEmail}, ConfigUuid);
    return get(setEmail, {
        params: {
            sign,
            applyId,
            contactEmail,
        }
    });
}



export {clearCookie, initVm, getHost};

